import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as Styles from "../components/Layout/styles"
import { Link } from "gatsby"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Política de Privavidade" />
    <Styles.Policy>
      <Styles.MenuPolicy>
        <Styles.MenuPolicyItem>
          <Link activeClassName="active" to="/politica-de-privacidade">
            Política de Privacidade
          </Link>
        </Styles.MenuPolicyItem>
        <Styles.MenuPolicyItem>
          <Link to="/termos-de-uso">Termos de Uso</Link>
        </Styles.MenuPolicyItem>
      </Styles.MenuPolicy>
      <Styles.LegalText>
        <h4>POLÍTICA DE PRIVACIDADE DA PLATAFORMA VAREJO INTEGRADO</h4>
        <p>
          Este instrumento contempla a Política de Privacidade da plataforma de
          marketplace da Varejo Integrado. Antes de acessar e usar a Plataforma,
          certifique-se de que compreendeu todas as condições estabelecidas
          neste instrumento. Eventuais dúvidas deverão ser sanadas em contato
          prévio com a equipe da Varejo Integrado, por meio do e-mail:
          meajuda@mercedobairro.com. Após ler, compreender e concordar com todo
          o conteúdo desta Política de Privacidade, você poderá aderir a ela.
          Caso você não concorde com esta Política de Privacidade, você deverá
          imediatamente suspender o uso da Plataforma. Você somente poderá
          utilizar a Plataforma caso aceite integralmente esta Política de
          Privacidade.{" "}
          <strong id="definicoes">
            A utilização da Plataforma implica a aceitação plena, irrestrita e
            sem reservas de todos os termos e condições previstos neste
            documento, inclusive no caso de eventuais revisões ou publicação de
            novas versões. No caso de revisões ou publicações de novas versões
          </strong>
          , você será avisado previamente e, caso não concorde com as
          alterações, deverá descontinuar o uso da Plataforma. O uso continuado
          da Plataforma, após a revisão da Política de Privacidade, implica
          aceitação das alterações, sem ressalvas.
        </p>
        <br />
        <p>
          <strong>1. DEFINIÇÕES</strong>
        </p>
        <p style={{ marginLeft: 20 }}>
          <strong>1.1.</strong> <u>Definições.</u> Os termos iniciados em letra
          maiúscula indicados a seguir serão utilizados com o significado
          detalhado abaixo:
          <br />
          <br />
          <strong>(a) “Atacadista”:</strong> é o comerciante de atacado,
          distribuidor ou indústria de alimentos ou bebidas que utiliza a
          Plataforma para vender e distribuir produtos aos Mercados cadastrados
          na Plataforma.
          <br />
          <br />
          <strong>(b) “Controlador”:</strong> é a pessoa, natural ou jurídica, a
          quem competem as decisões referentes ao Tratamento de Dados Pessoais.
          <br />
          <br />
          <strong>(c) “Dado Não Pessoal”: </strong>são informações fornecidas,
          coletadas ou processadas que não permitem identificar uma pessoa
          natural, tais como dados técnicos e agregados.
          <br />
          <br />
          <strong>(d) “Dado Pessoal”: </strong>são informações fornecidas,
          coletadas ou processadas que identificam ou permitem identificar uma
          pessoa natural, tais como seu nome, e-mail e telefone.
          <br />
          <br />
          <strong>(e) “Dado Pessoal Sensível”: </strong> são Dados Pessoais
          sobre origem racial ou étnica, convicção religiosa, opinião política,
          filiação a sindicato ou a organização de caráter religioso, filosófico
          ou político, dado referente à saúde ou à vida sexual, dado genético ou
          biométrico, quando vinculado a uma pessoa natural.
          <br />
          <br />
          <strong>(f) “Mercado”: </strong> é o pequeno estabelecimento comercial
          que utiliza a Plataforma para adquirir, para revenda, alimentos,
          bebidas e outros produtos dos Atacadistas cadastrados na Plataforma.
          <br />
          <br />
          <strong>(g) “Operador”: </strong> é a pessoa, natural ou jurídica, que
          realiza operações de Tratamento de Dados Pessoais em nome do
          Controlador.
          <br />
          <br />
          <strong>(h) “Plataforma”: </strong> é a ferramenta de intermediação de
          negócios constituída por uma aplicação de web software de
          responsabilidade, propriedade e operação da Varejo Integrado. A
          Plataforma tem por finalidade promover a intermediação de negócios
          entre os Mercados, de um lado, e os Atacadistas, de outro lado.
          <br />
          <br />
          <strong>(i) “Política de Privacidade”: </strong> é este instrumento,
          que compõe o conjunto de regras que disciplina o Tratamento de Dados
          Pessoais no âmbito da Plataforma.
          <br />
          <br />
          <strong>(j) “Tratamento”: </strong> são quaisquer operações envolvendo
          Dados Pessoais e/ou Dados Não Pessoais, tais como coleta, produção,
          recepção, classificação, utilização, acesso, reprodução, transmissão,
          distribuição, processamento, arquivamento, armazenamento, eliminação,
          avaliação, controle, modificação, comunicação, transferência, difusão
          ou extração.
          <br />
          <br />
          <strong>(k) “Termos de Uso”:</strong> é o instrumento que disciplina
          os termos e as condições gerais que regerão o acesso e o uso da
          Plataforma pelos Usuários. A Varejo Integrado poderá estabelecer
          Termos de Uso específicos e aplicáveis a determinados produtos ou
          funcionalidades, conforme o caso, que complementarão e prevalecerão
          sobre os Termos de Uso gerais.
          <br />
          <br />
          <strong>(l) “Usuário”: </strong> são os Atacadistas e Mercados
          cadastrados na Plataforma. Os Usuários podem ser tanto pessoas físicas
          como jurídicas. Os Usuários são identificados e qualificados no
          momento de preenchimento do formulário para acesso e uso da
          Plataforma.
          <br />
          <br />
          <strong id="objeto">(m) “Varejo Integrado”: </strong>é Varejo
          Integrado Serviços e Tecnologia Ltda., inscrita no CNPJ sob o n.º
          35.394.112/0001-59.
        </p>
        <br />
        <p>
          <strong>2. Objeto</strong>
        </p>
        <p style={{ marginLeft: 20 }}>
          <strong id="consentimento">2.1.</strong> <u>Objeto.</u> Esta Política
          de Privacidade tem por objeto disciplinar os termos e as condições
          gerais que regerão o Tratamento de Dados Pessoais e de Dados Não
          Pessoais pela Varejo Integrado e é aplicável aos Usuários e eventuais
          contrapartes dos Usuários que tenham seus Dados Pessoais inseridos,
          coletados ou processados por meio da Plataforma.
        </p>
        <br />
        <p>
          <strong>3. CONSENTIMENTO DO TITULAR </strong>
        </p>
        <p style={{ marginLeft: 20 }}>
          <strong>3.1.</strong> <u>Consentimento do Usuário.</u> Ao acessar a
          Plataforma e usar os serviços disponibilizados pela Varejo Integrado,
          o Usuário expressamente consente e autoriza a Varejo Integrado a
          realizar o Tratamento de seus Dados Pessoais para as finalidades
          determinadas e previstas nesta Política de Privacidade e de acordo com
          os termos e condições detalhados neste instrumento. O consentimento do
          Usuário poderá ser revogado a qualquer tempo, de forma gratuita,
          devendo, neste caso, o Usuário entrar em contato com a equipe da
          Varejo Integrado por meio dos canais de comunicação oficiais
          informados nesta Política de Privacidade. A revogação do consentimento
          do Usuário implicará a suspensão ou o bloqueio de seu acesso à
          Plataforma, sem qualquer ônus ou penalidade para a Varejo Integrado. A
          revogação do consentimento não terá efeitos retroativos, sendo
          ratificados os Tratamentos de Dados Pessoais realizados sob amparo do
          consentimento anteriormente manifestado pelo Usuário.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong id="coletados">3.2.</strong>{" "}
          <u>Consentimento de contrapartes do Usuário.</u> Ao acessar a
          Plataforma e usar os serviços disponibilizados pela Varejo Integrado,
          o Usuário declara e garante que obteve o consentimento expresso e por
          escrito de todos os titulares de Dados Pessoais que sejam contrapartes
          do Usuário (e.g., clientes, fornecedores, prestadores de serviços,
          empregados, entre outros), e que tenham seus Dados Pessoais inseridos,
          coletados ou processados por meio da Plataforma em nome do Usuário.
        </p>
        <br />
        <p>
          <strong>4. DADOS COLETADOS </strong>
        </p>
        <p style={{ marginLeft: 20 }}>
          <strong>4.1. </strong>
          <u> Dados de Usuários e de contrapartes dos Usuários.</u> Ao utilizar
          a Plataforma e interagir com a Varejo Integrado, a Varejo Integrado
          poderá realizar o Tratamento de Dados Pessoais e de Dados Não Pessoais
          <strong>(a)</strong> dos Usuários e <strong>(b)</strong> de eventuais
          contrapartes dos Usuários em suas operações comerciais e empresariais,
          tais como clientes, fornecedores, prestadores de serviços, empregados,
          entre outros.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.2. </strong>
          <u>
            {" "}
            Atuação da Varejo Integrado como Controlador ou Operador de Dados
            Pessoais.
          </u>{" "}
          A Varejo Integrado realizará o Tratamento de Dados Pessoais em nome
          próprio e/ou em nome do Usuário. Quando a Varejo Integrado realizar o
          Tratamento de Dados Pessoais em nome próprio, a Varejo Integrado
          atuará como Controlador. Quando a Varejo Integrado realizar o
          Tratamento de Dados Pessoais em nome do Usuário, a Varejo Integrado
          atuará como Operador de Dados Pessoais em nome do Usuário.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.3. </strong>
          <u>
            Responsabilidades do Usuário como Controlador de Dados Pessoais.
          </u>
          {"  "}A Varejo Integrado poderá realizar o Tratamento de Dados
          Pessoais em nome dos Usuários quando estes incluírem Dados Pessoais de
          terceiros na Plataforma ou usarem a Plataforma para realizar o
          Tratamento de Dados Pessoais de terceiros. A Varejo Integrado não tem
          controle sobre quais Dados Pessoais de terceiros o Usuário poderá
          inserir, coletar ou processar por meio da Plataforma, nem como tais
          Dados Pessoais serão utilizados pelo Usuário. Os Dados Pessoais de
          terceiros inseridos pelo Usuário ou coletados e processados pelo
          Usuário por meio da Plataforma ficarão à disposição dos Usuários,
          cabendo aos Usuários toda e qualquer responsabilidade a respeito da
          forma pela qual tais Dados Pessoais são objeto de Tratamento,
          inclusive pela obtenção de consentimento prévio e por escrito por
          parte do titular, nos termos da legislação aplicável, devendo manter a
          Varejo Integrado indene e livre de qualquer responsabilidade a esse
          respeito.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.4. </strong>
          <u>
            Responsabilidade da Varejo Integrado perante contrapartes dos
            Usuários
          </u>{" "}
          A Varejo Integrado não tem qualquer relação com as contrapartes dos
          Usuários que eventualmente tenham seus Dados Pessoais inseridos ou
          coletados pelo uso da Plataforma. Os titulares de tais Dados Pessoais
          devem exercer seus direitos sobre seus Dados Pessoais diretamente
          perante os Usuários, podendo a Varejo Integrado operacionalizar
          requisições de bloqueio ou eliminação de Dados Pessoais em benefício
          de tais terceiros. Em caso de recebimento de pedidos de bloqueios ou
          eliminação de Dados Pessoais de terceiros, a Varejo Integrado deverá
          responder ao terceiro e encaminhar à solicitação ao Usuário em prazo
          razoável, para que o Usuário adote as medidas adequadas e necessárias,
          nos termos da legislação aplicável.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.5. </strong>
          <u>
            Detalhamento dos Dados Pessoais coletados pela Varejo Integrado. A
            Varejo Integrado poderá realizar o Tratamento dos seguintes Dados
            Pessoais:
          </u>{" "}
          <br />
          <br />
          <strong>(a)</strong> Dados de contato: nome, RG, CPF, data de
          nascimento, estado civil, endereço, e-mail e telefone.
          <br />
          <br />
          <strong>(b)</strong> Dados da conta do Usuário na Plataforma: nome de
          usuário, senha e pergunta secreta.
          <br />
          <br />
          <strong>(c)</strong> Dados de faturamento: endereço de cobrança,
          método de pagamento, dados de conta bancária, número de cartão de
          crédito, data de expiração.
          <br />
          <br />
          <strong>(d)</strong> Dados de navegação: endereço IP, localização
          geográfica, identificador do dispositivo, [inserir outros, se
          aplicável].
          <br />
          <br />
          <strong>(e)</strong> Dados de contas conectadas com provedores de
          terceiros ou redes sociais: nome, e-mail, data de nascimento, telefone
          e e-mail.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.5.1. </strong>
          <u>Dados Pessoais Sensíveis.</u> A Varejo Integrado também poderá
          realizar o Tratamento de Dados Pessoais Sensíveis. Se isso acontecer,
          o Tratamento de tais Dados Pessoais Sensíveis será precedido de prévio
          e expresso consentimento do Usuário ou do titular, conforme o caso.
          Caso o Usuário venha a coletar Dados Pessoais Sensíveis de terceiros,
          o Usuário também deverá garantir que o Tratamento de tais Dados
          Pessoais Sensíveis somente será realizado se precedido de prévio e
          expresso consentimento do titular.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.6. </strong>
          <u>
            Detalhamento dos Dados Não Pessoais coletados pela Varejo Integrado.
          </u>{" "}
          A Varejo Integrado poderá realizar o Tratamento dos seguintes Dados
          Não Pessoais: <strong>(a)</strong> histórico de navegação, dados sobre
          sequência de cliques, informações sobre data e horário de acesso a
          páginas, total de visitas, interações com outras páginas e métodos
          utilizados para deixar a página, tempo de resposta e tempo total de
          visita; e <strong>(b)</strong> dados não identificadores sobre
          dispositivos eletrônicos (incluindo fabricante e modelo), sistema
          operacional, navegador de internet, resolução de tala, idioma, e rede
          Wi-Fi.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.7. </strong>
          <u>Combinação de Dados Pessoais e de Dados Não Pessoais.</u> Caso a
          Varejo Integrado combine Dados Pessoais e Dados Não Pessoais, a
          informação combinada será tratada como Dado Pessoal pela Varejo
          Integrado enquanto essa informação permanecer combinada ou não for
          anonimizada.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.8. </strong>
          <u>Outros Dados coletados pela Varejo Integrado.</u> Além dos Dados
          Pessoais e dos Dados Não Pessoais, a Varejo Integrado também poderá
          coletar, processar e usar outros dados dos Usuários, notadamente dados
          relacionados com pessoas jurídicas, tais como CNPJ e outros
          informações financeiras e de faturamento para assegurar determinados
          pagamentos e a emissões de boletos bancários e notas fiscais.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.9. </strong>
          <u>Uso de Dados Não Pessoais.</u> A Varejo Integrado poderá realizar o
          tratamento de Dados Não Pessoais e outros dados que não permitam a
          identificação de uma pessoa natural como bem lhe aprouver, a seu
          discricionário critério, sem a necessidade de qualquer autorização
          prévia ou comunicação com os Usuários, observados apenas os eventuais
          limites previstos na legislação aplicável. Os Usuários não terão
          qualquer direito sobre os Dados Não Pessoais coletados ou gerados por
          meio da Plataforma, tais como perfil de consumo de clientes, volume de
          vendas de determinado produto, market share, entre outros. Nada neste
          instrumento deverá ser interpretado no sentido de atribuir à Varejo
          Integrado qualquer obrigação de compartilhamento de Dados Não
          Pessoais.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>4.10. </strong>
          <u>Meios de coleta de dados</u> A Varejo Integrado poderá coletar
          Dados Pessoais e Dados Não Pessoais das seguintes formas:{" "}
          <strong>(a)</strong> por meio do uso dos serviços disponibilizados
          pela Varejo Integrado, quando o Usuário navega na Plataforma, envia
          informações e conteúdo pela Plataforma ou entra em contato com a
          Varejo Integrado, por meio de qualquer canal de atendimento;{" "}
          <strong>(b)</strong> por meio do fornecimento voluntário de dados
          pelos Usuários, quando o Usuário informa seus dados para fins de
          registro na Plataforma, envia documentos para a Varejo Integrado ou
          entra em contato com a Varejo Integrado, por meio de qualquer canal de
          atendimento; e <strong>(c)</strong> por meio de fontes independentes,
          quando a Varejo Integrado realiza pesquisas perante instituições
          bancárias, processadores de pagamentos, empresas ou agências de
          proteção de crédito, entre outros.
        </p>
        <br />
        <p>
          <strong>
            5. FINALIDADES E REQUISITOS PARA TRATAMENTO DE DADOS PESSOAIS
          </strong>
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>5.1. </strong>
          <u>Finalidades.</u> A Varejo Integrado realizará o Tratamento de Dados
          Pessoais para o atendimento das finalidades determinadas descritas
          abaixo:
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>(a)</strong> <u>Prestação de serviços:</u> para disponibilizar
          e operar todas as funcionalidades e serviços da Plataforma.
          <br />
          <br />
          <strong>(b)</strong> <u>Aprimoramento de serviços:</u> para
          desenvolver e melhorar as funcionalidades e serviços da Plataforma,
          experiência e a comunicação com os Usuários, inclusive para criação de
          novas funcionalidades, serviços ou produtos.
          <br />
          <br />
          <strong>(c)</strong> <u>Assistência técnica:</u> para solucionar
          problemas técnicos, falhas e erros, inclusive de segurança, que venham
          a ocorrer no âmbito da Plataforma.
          <br />
          <br />
          <strong>(d)</strong> <u>Comunicação com os Usuários:</u> para permitir
          a comunicação com os Usuários, inclusive por meio de avisos gerais ou
          personalizados, notificações, comunicados, alteração de prazos e
          políticas.
          <br />
          <br />
          <strong>(e)</strong> <u> Estatísticas:</u> para análise de dados
          estatísticos e realização de pesquisas sobre produtos, serviços e
          comunicações, que podem ser usados pela Varejo Integrado ou por seus
          parceiros e contratados.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>5.2. </strong>
          <u>Requisitos.</u> A Varejo Integrado somente realizará o Tratamento
          de Dados Pessoais se e quando for necessário para (a) cumprir um
          contrato celebrado com o Usuário, tais como os Termos de Uso da
          Plataforma; (b) cumprir uma obrigação decorrente de quaisquer leis e
          regulamentos aplicáveis a que a Varejo Integrado e/ou os Usuários
          estejam sujeitos; e (c) atender aos interesses legítimos da Varejo
          Integrado, tais como para manter e aprimorar os serviços que prestamos
          aos Usuários.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>5.3. </strong>
          <u>Gravação de ligações ou conversas.</u> Para fins de qualificação,
          treinamento e para a segurança do Usuário, a Varejo Integrado pode
          monitorar ou gravar conversas telefônicas com o Usuário ou com a
          pessoa agindo em nome do Usuário. Ao se comunicar com a Varejo
          Integrado, o Usuário entende, concorda e autoriza que as comunicações
          podem ser ouvidas, monitoradas e/ou gravadas sem aviso ou notificação
          prévios.
        </p>
        <br />
        <p>
          <strong>
            6. ARMAZENAMENTO E TÉRMINO DO TRATAMENTO DE DADOS PESSOAIS
          </strong>
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>6.1. </strong>
          <u> Local de armazenamento.</u> Os Dados Pessoais e os Dados Não
          Pessoais objeto de Tratamento pela Varejo Integrado podem ser
          armazenados em centros de armazenamento físicos ou virtuais, por meio
          de serviços disponibilizados por parceiros que podem estar localizados
          no Brasil e/ou em outros países, tais como Estados Unidos da América e
          países da União Europeia.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>6.2. </strong>
          <u>Prazo de armazenamento.</u> Os Dados Pessoais objeto de Tratamento
          pela Varejo Integrado serão armazenados enquanto o Usuário mantiver
          uma conta ativa na Plataforma e pelo tempo necessário para que a
          Varejo Integrado possa cumprir com as obrigações assumidas perante os
          Usuários, nos termos deste instrumento e dos Termos de Uso, e perante
          qualquer autoridade pública, conforme determinado pela legislação e
          regulamentação aplicáveis. A partir do momento em que o armazenamento
          dos Dados Pessoais dos Usuários e de suas contrapartes deixar de ser
          necessário, a Varejo Integrado realizará a remoção e a eliminação de
          tais dados.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>6.3. </strong>
          <u>
            Encerramento do uso da Plataforma e término do Tratamento de Dados
            Pessoais.
          </u>{" "}
          Caso o Usuário deixe de utilizar os serviços oferecidos na Plataforma
          por qualquer razão, os seus Dados Pessoais serão anonimizados ou
          excluídos definitivamente da Plataforma e dos sistemas de
          armazenamento da Varejo Integrado, exceto se a retenção e o
          armazenamento de Dados Pessoais for necessário para (a) cumprimento de
          obrigação legal ou contratual, (b) atendimento de interesses legítimos
          da Varejo Integrado ou (c) exercício regular de direitos em processo
          judicial, administrativo ou arbitral. Nesses casos, a retenção e o
          armazenamento de Dados Pessoais continuarão a ser realizados. Os Dados
          Pessoais também poderão ser anonimizadaos pela Varejo Integrado a
          qualquer momento, hipótese em que poderão ser mantidos pela Varejo
          Integrado para seu uso exclusivo mesmo após o término da relação com o
          titular dos Dados Pessoais.
        </p>
        <br />
        <p>
          <strong>7. SEGURANÇA DE DADOS</strong>
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>7.1. </strong>
          <u>Medidas de proteção de dados.</u> A Varejo Integrado emprega seus
          melhores esforços para proteger os Dados Pessoais e os Dados Não
          Pessoais objeto de Tratamento contra perda, roubo ou qualquer
          modalidade de uso ou acesso indevido e não autorizado, divulgação,
          alteração e destruição. A Varejo Integrado aplica as melhores práticas
          de segurança no Tratamento de Dados Pessoais e de Dados Não Pessoais,
          tais como criptografia, monitoramento e testes de segurança
          periódicos, <i>firewall</i>, entre outros.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>7.2. </strong>
          <u>Ausência de garantias.</u> A despeito de todas as medidas de
          segurança adotadas pela Varejo Integrado, não é possível garantir e a
          Varejo Integrado não garante a não ocorrência de vazamentos,
          interceptações e violações de sistemas e bases de dados. Nada nesta
          Política de Privacidade ou nos Termos de Uso deverá ser interpretado
          nesse sentido.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>7.3. </strong>
          <u>Tratamento de incidentes de segurança.</u> Na hipótese de
          ocorrência de um incidente de segurança que resulte em destruição,
          perda, alteração, acesso não autorizado ou vazamento de Dados
          Pessoais, a Varejo Integrado avaliará os riscos e eventuais danos
          causados e deverá comunicar, em prazo razoável, o Usuário e as
          autoridades públicas competentes a esse respeito. A Varejo Integrado
          adotará todas as medidas disponíveis e razoáveis para mitigar
          eventuais danos causados aos Usuários e ou a quaisquer terceiros
          titulares de Dados Pessoais que tenham sido objeto de Tratamento pela
          Varejo Integrado.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>7.4. </strong>
          <u>Responsabilidades do Usuário por incidentes de segurança</u> É
          responsabilidade única e exclusiva do Usuário qualquer incidente de
          segurança que ocorra em sua conta ou com seus Dados Pessoais, ou com
          Dados Pessoais de terceiros que o Usuário venha a imputar ou processar
          na Plataforma, em razão de atos ou fatos nos quais o Usuário concorra
          com culpa exclusiva ou concorrente, tais como atos de divulgação de
          suas informações de acesso (login e senha) para terceiros, utilização
          de computadores de acesso público (e.g., <i>lan house</i>) ou qualquer
          outra forma de conexão com a internet que não seja privada e segura.
          Nesses casos, nenhuma responsabilidade poderá ser imputada à Varejo
          Integrado ou aos seus parceiros provedores de serviços de segurança e
          de armazenamento de dados.
        </p>
        <br />
        <p>
          <strong>8. COMPARTILHAMENTO DE DADOS COM TERCEIROS</strong>
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>8.1. </strong>
          <u>Consentimento.</u> Ao acessar a Plataforma e usar os serviços
          disponibilizados pela Varejo Integrado, o Usuário expressamente
          consente e autoriza a Varejo Integrado a realizar o compartilhamento
          de seus Dados Pessoais para as finalidades determinadas e previstas
          abaixo. Caso o Usuário não concorde com o compartilhamento de seus
          Dados Pessoais, deverá imediatamente suspender o acesso e o uso da
          Plataforma e comunicar, por escrito, a Varejo Integrado a esse
          respeito. A revogação do consentimento não terá efeitos retroativos,
          sendo ratificados os Tratamentos de Dados Pessoais realizados sob
          amparo do consentimento anteriormente manifestado pelo Usuário.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>8.2. </strong>
          <u>
            Compartilhamento de Dados Pessoais com contratados e empresas
            parceiras.
          </u>{" "}
          A Varejo Integrado poderá disponibilizar ou compartilhar Dados
          Pessoais com contratados, desenvolvedores de software e prestadores de
          serviços que prestam serviços para a Varejo Integrado ou atuam em
          conjunto com a Varejo Integrado para executar determinadas
          funcionalidades e atividades no âmbito da Plataforma, incluindo
          agências de marketing, prestadores de serviços de bancos de dados,
          prestadores de serviços de recuperação de dados após desastres e de
          organização de cópias de segurança, processadores e meios de
          pagamentos, instituições financeiras, serviços de e-mail, serviços de
          acesso remoto, consultores jurídicos e financeiros, entre outros. O
          acesso a Dados Pessoais por tais terceiros terá como finalidade
          permitir o uso e o desenvolvimento da Plataforma, a adequada prestação
          de serviços pela Varejo Integrado e o atendimento de contratos e
          obrigações legais e regulamentares.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>8.3. </strong>
          <u>
            Compartilhamento de Dados Pessoais com empresas do mesmo grupo.
          </u>{" "}
          A Varejo Integrado poderá disponibilizar ou compartilhar Dados
          Pessoais com subsidiárias, controladas, controladoras, investidas e
          demais empresas de seu grupo econômico com as finalidades de:{" "}
          <strong>(a)</strong>
          desenvolvimento de novos produtos e serviços; <strong>
            (b)
          </strong>{" "}
          oferta de produtos e serviços que melhor atendam aos interesses dos
          Usuários; e <strong>(c)</strong> geração de dados estatísticos e
          agregados acesso do uso da Plataforma e dos perfis dos Usuários.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>8.4. </strong>
          <u>Compartilhamento de Dados Pessoais em operações societárias.</u> A
          Varejo Integrado poderá vender ou comprar outras empresas e ativos,
          assim como poderá ser vendida ou comprada por outras empresas, no
          Brasil ou no exterior. Em caso de venda, cisão, fusão, incorporação,
          transformação ou qualquer outra operação societária, os Dados Pessoais
          dos Usuários e/ou de suas contrapartes poderão ser compartilhados com
          terceiros ou poderão fazer parte dos ativos intangíveis transferidos
          ou alienados, independentemente de qualquer aviso prévio ou
          autorização.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>8.5. </strong>
          <u>
            Compartilhamento de Dados Pessoais com autoridades públicas.
          </u>{" "}
          Sempre que exigido por lei ou por determinação de autoridade pública,
          a Varejo Integrado poderá divulgar ou compartilhar, no todo ou em
          parte, com ou sem aviso prévio, os Dados Pessoais dos Usuários e/ou de
          suas contrapartes. A Varejo Integrado também poderá compartilhar Dados
          Pessoais para responder a processos judiciais ou para participar de
          eventuais litígios ou demandas judiciais ou administrativas de
          qualquer natureza.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>8.6. </strong>
          <u>Transferência internacional de Dados Pessoais.</u> A Varejo
          Integrado poderá realizar transferências internacionais de Dados
          Pessoais com o objetivo de disponibilizar algumas das funcionalidades
          da Plataforma ou para obter informações que possam ajudar a
          desenvolver e aprimorar a Plataforma. Neste caso, a Varejo Integrado
          deverá fazer com que os parceiros internacionais que venham a receber
          Dados Pessoais utilizem critérios e padrões de segurança compatíveis
          com aqueles previstos nesta Política de Privacidade.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>8.7. </strong>
          <u>Proteção de direitos e segurança. </u> A Varejo Integrado se
          reserva o direito de compartilhar Dados Pessoais e/ou Dados Não
          Pessoais sempre que acreditar e tiver motivos suficientes para
          considerar que a atividade do Usuário seja suspeita, ilegal ou
          prejudicial à Varejo Integrado, aos demais Usuários ou a quaisquer
          terceiros.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>8.8. </strong>
          <u>Compartilhamento de Dados Não Pessoais.</u> Nada nesta Política de
          Privacidade limita ou restringe o compartilhamento de Dados Não
          Pessoais, a exclusivo e discricionário critério da Varejo Integrado,
          sem a necessidade de qualquer autorização ou comunicação prévia.
        </p>
        <br />
        <p>
          <strong>
            9. USO DE COOKIES E OUTRAS TECNOLOGIAS DE RASTREAMENTO
          </strong>
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>9.1. </strong>
          <u>Finalidades do uso de cookies e tecnologias de rastreamento.</u> A
          Varejo Integrado poderá usar cookies e outras tecnologias de
          monitoramento ou rastreamento com a finalidade de <strong>(a)</strong>{" "}
          disponibilizar e operar as funcionalidades e serviços da Plataforma;{" "}
          <strong>(b)</strong> melhorar a experiência dos Usuários na
          Plataforma; <strong>(c)</strong> identificar problemas técnicos e
          falhas; e <strong>(d)</strong> monitorar e melhorar o desempenho geral
          da Plataforma.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>9.2. </strong>
          <u>
            Exclusão ou bloqueio de cookies e tecnologias de rastreamento.
          </u>{" "}
          Caso o Usuário tenha interesse em excluir ou bloquear determinado
          cookie ou outra tecnologia de rastreamento, o Usuário deverá verificar
          o suporte de seu provedor de internet para identificar o arquivo ou
          diretório que armazena o cookie ou demais tecnologias de rastreamento
          e então excluí-lo ou bloqueá-lo. A exclusão ou o bloqueio de cookies e
          demais tecnologias de rastreamento poderá afetar o normal e adequado
          funcionamento da Plataforma, inclusive com prejuízos para a
          experiência de navegação do Usuário.
        </p>
        <br />
        <p>
          <strong>10. DIREITOS DO TITULAR SOBRE SEUS DADOS PESSOAIS</strong>
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>10.1. </strong>
          <u>Direitos do titular</u> Por meio dos canais de comunicação oficiais
          da Varejo Integrado, conforme previsto abaixo, os titulares de Dados
          Pessoais objeto de Tratamento pela Varejo Integrado na condição de
          Controladora poderão solicitar à Varejo Integrado, a qualquer momento
          e mediante requisição por escrito: <strong>(a)</strong> confirmação da
          existência de Tratamento de Dados Pessoais; <strong>(b)</strong>{" "}
          acesso a seus Dados Pessoais; <strong>(c)</strong>
          correção de Dados Pessoais incompletos, inexatos ou desatualizados;{" "}
          <strong>(d)</strong> anonimização, bloqueio ou eliminação de Dados
          Pessoais desnecessários, excessivos ou tratados em desconformidade com
          esta Política de Privacidade ou com a legislação aplicável;{" "}
          <strong>(e)</strong> eliminação de Dados Pessoais, ressalvadas as
          hipóteses em que tal eliminação não puder ser realizada, nos termos
          desta Política de Privacidade e da legislação aplicável;{" "}
          <strong>(f)</strong> informação sobre as entidades públicas e privadas
          com as quais seus Dados Pessoais foram compartilhados;{" "}
          <strong>(g)</strong> informação sobre a possibilidade de não fornecer
          ou revogar o consentimento para Tratamento de seus Dados Pessoais e
          eventuais consequências negativas.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>10.2. </strong>
          <u>Eliminação de Dados Pessoais.</u> Caso o titular solicite a
          exclusão de seus Dados Pessoais, a Varejo Integrado estará autorizada
          a excluir ou anonimizar os Dados Pessoais do solicitante, ainda que
          isso implique na remoção de todos os seus Dados Pessoais da Plataforma
          em caráter definitivo. Caso os Dados Pessoais sejam também mantidos em
          arquivos e sistemas internos dos Usuários, caberá única e
          exclusivamente aos Usuários adotarem as providências para exclusão ou
          anonimização de tais Dados Pessoais.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>10.3. </strong>
          <u>
            Atendimento de solicitações dos titulares de Dados Pessoais.
          </u>{" "}
          Antes de atender a qualquer solicitação dos titulares de Dados
          Pessoais, a Varejo Integrado poderá solicitar informações adicionais
          para confirmar a identidade do solicitante e para fins de segurança.
          Caso a solicitação seja infundada ou não tenha respaldo legal ou
          contratual, a Varejo Integrado também poderá cobrar uma taxa,
          solicitar o reembolso dos custos incorridos para o atendimento de tal
          solicitação ou simplesmente recusar o atendimento da solicitação. Nas
          hipóteses em que a Varejo Integrado não seja a Controladora dos Dados
          Pessoais do solicitante, a Varejo Integrado informará o solicitante a
          respeito de sua posição como Operadora de Dados Pessoais e, se
          possível, indicará o Controlador, responsável por atender a
          solicitação do titular dos Dados Pessoais.
        </p>
        <br />
        <p>
          <strong>11. DÚVIDAS OU QUESTÕES</strong>
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>11.1. </strong>
          <u>Contato.</u> Em caso de dúvidas ou questões sobre esta Política de
          Privacidade ou sobre o exercício dos direitos previstos neste
          instrumento ou na legislação vigente, por favor entre em contato com a
          equipe da Varejo Integrado por meio do e-mail
          meajuda@mercedobairro.com ou do telefone (11) 93447-0146. A Varejo
          Integrado também poderá ser contatada em seu endereço físico,
          localizado no Município de São Paulo, no Estado de São Paulo, na
          Avenida Brigadeiro Faria Lima, n.º 2232, apartamento 16-A, Edifício
          Dijon, Jardim Paulistano, CEP 01489-900.
        </p>
        <br />
        <p>
          <strong>12. DISPOSIÇÕES GERAIS</strong>
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>12.1. </strong>
          <u>Violação da Política de Privacidade.</u> No caso de descumprimento,
          pelo Usuário, de qualquer disposição desta Política de Privacidade, a
          Varejo Integrado poderá, independentemente de qualquer aviso,
          notificação ou de qualquer outra formalidade, interromper, suspender
          ou cancelar, de imediato, temporária ou definitivamente, a conta e o
          acesso do Usuário à Plataforma, sem prejuízo de quaisquer outros
          direitos assegurados à Varejo Integrado por lei, pelos Termos de Uso
          e/ou por esta Política de Privacidade.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>12.2. </strong>
          <u>Revisões ou atualizações.</u> A Varejo Integrado poderá alterar ou
          revisar, a qualquer tempo, esta Política de Privacidade. Fica
          estabelecido que as novas versões desta Política de Privacidade
          entrarão em vigor na data de sua publicação, sendo que o Usuário
          somente poderá continuar a utilizar a Plataforma caso concorde ou não
          discorde expressamente com as referidas alterações. O Usuário poderá,
          caso não concorde com a referida nova versão, extinguir o seu cadastro
          da Plataforma (i.e. opt. out). Nessa hipótese, o vínculo contratual
          deixará de existir, a critério exclusivo do Usuário, sem qualquer
          penalidade ao Usuário. O uso da Plataforma, após ter sido comunicado a
          respeito das alterações, será considerado como aceite, pelo Usuário,
          da nova versão da Política de Privacidade.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>12.3. </strong>
          <u>Acordo integral. </u> Esta Política de Privacidade supera,
          substitui e prevalece sobre qualquer acordo ou contrato prévio,
          escrito ou verbal, que tenha sido levado a efeito pelas partes com
          relação aos assuntos aqui contemplados. Para que não restem dúvidas,
          esta Política de Privacidade não substitui os Termos de Uso da
          Plataforma. Tais documentos devem ser sempre lidos em conjunto e em
          caso de conflito entre suas disposições deverá prevalecer a regra mais
          específica.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>12.4. </strong>
          <u>Sucessores.</u> Esta Política de Privacidade vincula o Usuário e a
          Varejo Integrado ao seu fiel cumprimento, bem como seus sucessores a
          qualquer título, em caráter irrevogável e irretratável.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>12.5. </strong>
          <u>
            Necessidade de renúncia expressa e escrita e princípio da
            conservação.
          </u>{" "}
          A Varejo Integrado e os Usuários declaram e acordam expressamente que:
          <strong>(a)</strong> o não exercício ou o atraso no exercício de
          qualquer direito que lhes seja assegurado por este instrumento ou por
          lei não constituirá novação ou renúncia a tal direito, nem prejudicará
          o eventual exercício do mesmo; <strong>(b)</strong> a renúncia a
          qualquer desses direitos somente será válida se formalizada por
          escrito, se e quando tal renúncia não for vedada por lei; e{" "}
          <strong>(c)</strong>a nulidade ou invalidade de qualquer das cláusulas
          deste instrumento não prejudicará a validade e eficácia das demais
          cláusulas. Caso seja decretada a nulidade, anulabilidade ou ineficácia
          de qualquer das cláusulas deste instrumento, a Varejo Integrado tomará
          todas as providências, inclusive por meio da alteração deste
          instrumento, para que, em conformidade com a legislação em vigor, seja
          alcançado, na maior extensão possível, o resultado prático a que
          visava este instrumento com a(s) cláusula(s) inquinada(s).
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>12.6. </strong>
          <u>Títulos.</u> Os títulos das cláusulas ou capítulos desta Política
          de Privacidade são meramente ilustrativos, e foram inseridos para
          facilitar a compreensão do Usuário.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>12.7. </strong>
          <u>Legislação aplicável.</u> O Usuário declara-se ciente e concorda
          que, independentemente do local de onde esteja utilizando a
          Plataforma, esta Política de Privacidade é regida pelas leis
          brasileiras.
        </p>
        <br />
        <p style={{ marginLeft: 20 }}>
          <strong>12.8. </strong>
          <u>Foro.</u> Em caso de disputas ou desentendimentos a respeito desta
          Política de Privacidade, fica eleito o Foro da Comarca de São Paulo,
          Estado de São Paulo, para dirimir quaisquer dúvidas ou controvérsias,
          com a exclusão de qualquer outro, por mais privilegiado que seja ou
          venha a ser.
        </p>
        <br />
      </Styles.LegalText>
    </Styles.Policy>
  </Layout>
)

export default PrivacyPolicy
